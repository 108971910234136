import * as React from "react";
import { FunctionComponent, useState } from "react";
import { Button, Heading, Input, InputHelper, Link, Page } from "../components";
import { useStaticQuery, graphql } from "gatsby";

import { togglePopup } from "../utilities/index";

const Recaptcha = require("react-recaptcha");

const VALID_DATE_PATTERN = "\\d{1,2}/\\d{4}";

const onDateInput = event => {
  const { target } = event;
  target.setCustomValidity("");
  target.checkValidity();
};

const handleInvalidDate = event => {
  event.target.setCustomValidity("Please use the correct date format: MM/YYYY");
};

const CouponReplacementForm: FunctionComponent = (props: {
  pageContext: { GATSBY_HMCMGT_API_GATEWAY_URL: string };
}): JSX.Element => {
  const data = useStaticQuery(graphql`
    {
      accountNumberSample: file(name: { eq: "hmc-account-number-sample" }) {
        publicURL
      }
    }
  `);

  const {
    pageContext: { GATSBY_HMCMGT_API_GATEWAY_URL }
  } = props;

  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const recaptchaVerifyCallback = response => {
    setCaptchaVerified(true);
    setCaptchaToken(response);
  };

  const recaptchaExpiredCallback = _response => {
    setCaptchaVerified(false);
    setCaptchaToken("");
  };

  return (
    <Page layout="default" title="Request a Replacement Coupon Book">
      <form
        method="POST"
        action={`${GATSBY_HMCMGT_API_GATEWAY_URL}/coupon-book`}
      >
        <Heading style={{ textAlign: "center" }} level={1}>
          Request a Replacement Coupon Book
        </Heading>
        <p style={{ textAlign: "center" }}>
          {`Coupons will be sent to the address associated with your account on file. If you need to update your address, please complete the`}{" "}
          <Link href="/change-address">Change of Address Form</Link> first.
        </p>

        <hr />
        <fieldset>
          <legend>Owner Details</legend>

          <div>
            <div className="input--split">
              <label htmlFor="first_name">First Name</label>
              <Input id="first_name" name="first_name" required />
            </div>
            <div className="input--split">
              <label htmlFor="last_name">Last Name</label>
              <Input id="last_name" name="last_name" required />
            </div>
          </div>

          <div>
            <label htmlFor="association">Association Name</label>
            <Input id="association" name="association_name" required />
          </div>

          <div>
            <label htmlFor="unit_number">Unit Number</label>
            <Input id="unit_number" name="unit_number" required />
          </div>

          <div>
            <label htmlFor="account_number">Hawaiiana Account Number</label>
            <Input
              id="account_number"
              name="hawaiiana_account_number"
              style={{ margin: "0" }}
              required
            />
            <InputHelper
              id="account-number-example"
              label="Need help locating your account number?"
              style={{ margin: "0 0 1rem 0" }}
              cb={togglePopup}
            >
              <img src={data.accountNumberSample.publicURL} />
            </InputHelper>
          </div>

          <div>
            <label htmlFor="phone_number">Phone Number</label>
            <Input id="phone_number" name="phone_number" type="tel" required />
          </div>

          <div>
            <label htmlFor="email_address">Email Address</label>
            <Input
              id="email_address"
              name="email_address"
              type="email"
              required
            />
          </div>
        </fieldset>

        <fieldset>
          <legend>Requested Coupon Date Range</legend>
          <div className="input--split">
            <label htmlFor="from">From</label>
            <Input
              id="from"
              name="date_range_from"
              type="text"
              required
              placeholder="MM/YYYY"
              onInput={onDateInput}
              pattern={VALID_DATE_PATTERN}
              onInvalid={handleInvalidDate}
            />
          </div>
          <div className="input--split">
            <label htmlFor="to">To</label>
            <Input
              id="to"
              name="date_range_to"
              type="text"
              required
              placeholder="MM/YYYY"
              onInput={onDateInput}
              pattern={VALID_DATE_PATTERN}
              onInvalid={handleInvalidDate}
            />
          </div>
        </fieldset>

        <div id="recaptcha-container">
          {typeof window !== "undefined" && (
            <Recaptcha
              elementId="recaptcha-container"
              render="explicit"
              sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
              verifyCallback={recaptchaVerifyCallback}
              onloadCallback={console.debug}
              expiredCallback={recaptchaExpiredCallback}
            />
          )}
        </div>
        <Input
          id="captcha_token"
          name="captcha_token"
          type="hidden"
          value={captchaToken}
          required
        />

        {isCaptchaVerified && (
          <p style={{ textAlign: "center" }}>
            <Button type="submit">Submit</Button>
          </p>
        )}
      </form>
    </Page>
  );
};

export default CouponReplacementForm;
